<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Mis Pedidos</h4>
            <div class="small text-muted">Administración de todos mis pedidos emitidos y recibidos</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Pedido">
              <i class="fa fa-plus"></i> Agregar
            </b-button>  

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>
      
      
      <b-card id="list-orders" v-if="table.mostrarFiltros">
        <b-row >
          <b-col sm="11">            
            <b-row>
              <b-col sm="3">
                <b-row>            
                  <b-col md="6" class="p-0 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  </b-col>
                  <b-col md="6" class="p-0 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  </b-col>   
                  <b-col sm="12" class="p-0"> 
                    <b-form-input type="number" 
                                  placeholder="Buscar por Nº de Pedido" 
                                  v-on:keyup.enter.native="filterOrders()"
                                  v-model="filters.number">
                    </b-form-input>         
                  </b-col>                                                                          
                </b-row>
              </b-col>
              <b-col sm="6">
                <b-row>     
                  <b-col sm="6" class="p-1">
                    <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>                            
                  <b-col sm="6" class="p-1">
                    <v-select :options="arr.filters.status" v-model="filters.status" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>  
                  <b-col sm="6" class="p-1">
                    <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>         
                  <b-col sm="6" class="p-1" v-if="parameters.haveReason">
                    <v-select :options="arr.filters.reasons" v-model="filters.reason" placeholder="Motivos" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>                             
                </b-row>
              </b-col>        
            </b-row>
          </b-col>
          <b-col md="1" class="p-1">
            <b-row>              
              <b-col sm="12" class="p-1">
                <b-button variant="outline-dark" @click="filterOrders()" class="pull-right mr-2" size="sm" title="Filtrar">
                  <b-icon icon="filter" style="font-size:15px;"></b-icon>                                
                </b-button>
              </b-col>
              <b-col sm="12" class="p-1">
                <b-link>
                  <export-excel
                    class = 'pull-right mr-2 mt-2'
                    :data = "arr.export"
                    worksheet = "Listado de Pedidos"
                    name = "list-orders.xls">
                    Exportar <b-icon icon="download"></b-icon>
                  </export-excel>            
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>              
      </b-card>
      
      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_orders">
          <b-card no-body>
            <b-tabs pills card vertical>
              <b-tab>
                <template #title>
                  <b-icon icon="receipt-cutoff" title="Pedidos"></b-icon>
                </template>                    
                <b-card-text>                            
                  <b-card>
                    <b-row>
                      <b-col sm="12">
                        <b-table class="mb-0 table-orders-custom"
                                ref="table"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="table.items"
                                :fields="table.fields"
                                :filter="table.filter"
                                :current-page="table.currentPage"
                                :per-page="table.perPage"
                                selectable
                                select-mode="single"
                                @row-selected="onRowSelected"  
                                :busy="table.isBusy"                      
                                v-if="table.items.length || table.isBusy">                       
                            
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:table-busy>
                              <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Cargando...</strong>
                              </div>
                            </template>

                            <template v-slot:cell(id)="data">
                              <span v-if="data.item.points_sales" :title="'Punto de Venta: (' + data.item.points_sales.point_sale + ') ' + data.item.points_sales.name">
                                <b-badge variant="dark">{{data.item.points_sales.point_sale}}</b-badge>
                              </span>

                              <div title="Numeración del sistema">
                                <b>#{{data.item.id}}</b>
                              </div>
                              <div v-if="data.item.nro_order_internal" 
                                  title="Numeración de un sistema externo"
                                  class="crud-orders-nro-internal">
                                <b-icon icon="link45deg"></b-icon>
                                <b>{{data.item.nro_order_internal}}</b>
                              </div>
                            </template>
        
                            <template v-slot:cell(date)="data">
                              {{moment(data.item.date).format('DD/MM/YYYY')}}
                            </template>

                            <template v-slot:cell(customers_id)="data">  
                              <div v-if="data.item.customer">            
                                <b-avatar :src="data.item.customer.image"
                                          v-if="data.item.customer.image">
                                </b-avatar>
                                <b-avatar v-else 
                                          icon="building"                                            
                                          variant="dark">
                                </b-avatar>                                                     
                                &nbsp;<b>{{data.item.customer.name}}</b>   
                                
                                <span v-if="data.item.customer.bcra_status">                                                    
                                  <span v-if="data.item.customer.bcra_status.situacion > situationCreditMax">
                                    &nbsp;
                                    <b-badge variant="danger" :id="'popover-situation-' + data.item.id">
                                      Sit {{data.item.customer.bcra_status.situacion}}
                                    </b-badge>
                                    <b-popover :target="'popover-situation-' + data.item.id" variant="danger" triggers="hover">
                                      <template #title>Riesgo Creditício</template>
                                      <div v-html="situationCreditMessage(data.item)"></div>
                                    </b-popover>                            
                                  </span>
                                </span>                                                

                                <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                              </div>
                            </template>

                            <template v-slot:cell(total)="data">       
                              <div v-if="data.item.status!='in_process'">
                                <div v-if="data.item.paid" class="order-total-table-paid">
                                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                                </div>
                                <div v-else class="order-total-table-nopaid">
                                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                                </div>
                              </div>
                            </template>

                            <template v-slot:cell(status)="data">       
                              <div v-html="getStatusName(data.item)"></div>
                            </template>

                            <template v-slot:cell(reason)="data">                                     
                              <div v-if="data.item.reason">
                                <span v-if="data.item.reason.reference">
                                  {{data.item.reason.reference}}
                                </span>
                                <span v-else>
                                  {{data.item.reason.name}}
                                </span>                                                                
                              </div>
                            </template>

                            <template v-slot:cell(status_system_manufacture)="data">                      
                              <span v-if="data.item.status_system_manufacture">                        
                                <span v-if="JSON.parse(data.item.status_system_manufacture).status == 'ok'" v-b-tooltip.hover :title="JSON.parse(data.item.status_system_manufacture).message">
                                  <b-icon icon="arrow-return-right" style="color: #c72eda;"></b-icon>                          
                                </span>                                   
                                <span v-if="JSON.parse(data.item.status_system_manufacture).status == 'error'" v-b-tooltip.hover :title="JSON.parse(data.item.status_system_manufacture).message">
                                  <b-icon icon="arrow-return-right" style="color: #ff1f1f;"></b-icon>                          
                                </span>                                   
                              </span>
                            </template>

                            <template v-slot:cell(observations)="data">
                              <div v-if="data.item.observation" v-b-tooltip.hover :title="data.item.observation">
                                <b-icon icon="chat-square-text"></b-icon>
                              </div>                        
                              <div v-if="data.item.observations_external">
                                <b-link :id="'popover-observations-external-' + data.index">
                                  <b-icon icon="chat-square-text-fill"></b-icon>
                                </b-link>
                              </div>   
                              <b-popover :target="'popover-observations-external-' + data.index" 
                                        variant="danger" 
                                        triggers="hover">
                                <template #title>Notas</template>
                                <div v-html="data.item.observations_external"></div>
                              </b-popover>      
                            </template>
                            
                            <template v-slot:cell(paid_info)="data">
                              <div v-if="data.item.paid_info" v-b-tooltip.hover :title="data.item.paid_info">
                                <b-icon icon="cash-stack"></b-icon>
                              </div>                        
                            </template>

                            <template v-slot:cell(delivery_code_tracking)="data">
                              <div v-if="data.item.delivery_code_tracking" v-b-tooltip.hover :title="data.item.delivery_code_tracking">
                                <b-icon icon="truck"></b-icon>
                              </div>                        
                            </template>        

                            <template v-slot:cell(invoice)="data">
                              <div v-if="data.item.sale_id" v-b-tooltip.hover :title="data.item.sale.type_voucher.name + ' ' + data.item.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.sale.number.toString().padStart(8,'0')">
                                <b-icon icon="receipt-cutoff"></b-icon>
                              </div>                                              
                            </template>                                        
                        </b-table>
                        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                  <b-icon icon="graph-up" title="Métricas"></b-icon>
                </template>                   
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-button @click="filterOrdersMetrics()" size="sm" type="button" variant="outline-dark" class="btn-pill float-right mb-3" v-b-tooltip.hover title="Actualizar Datos">
                        <i class="fa fa-refresh"></i> Actualizar datos
                      </b-button>                    
                    </b-col>                  
                    <b-col md="12">
                      <Metrics types="orders" :filters="filters" :key="itemFilterForceUpdate" v-if="itemFilterForceUpdate>0"/>                  
                    </b-col>
                  </b-row>          
                </b-card-text>
              </b-tab>
            </b-tabs>                  
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- PEDIDO SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="6">
                      <div class="crud-orders-title" v-if="itemSelected">
                        <div class="crud-orders-title">
                          <span class="crud-orders-code">
                            #{{this.itemSelected.id}}                             
                          </span><br>
                          <span v-if="itemSelected.customer">
                            {{this.itemSelected.customer.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12">       
                      <b-link v-if="tableSub.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                   
                      <b-table  class="mb-0 table-orders-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         
                        
                        <template v-slot:cell(quantity)="row"> 
                          {{row.item.quantity}}                          
                          <span v-if="row.item.unit_measurement">{{row.item.unit_measurement.reference}}</span>
                        </template>

                        <template v-slot:cell(product)="row">
                          <div v-html="getProductName(row.item)"></div>
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se seleccionó ningún pedido</b-alert>
                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>          

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_orders">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterOrders()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-orders-wizard">
          
          <tab-content title="General" icon="fa fa-edit" :before-change="orderValidDetailGeneral">          
            <b-row>      

              <b-col md="6">       
                <b-form-group label="Cliente">     
                  <v-select :options="arr.select.customers" 
                            v-model="crud.form.customers_id" 
                            placeholder="Selecionar Cliente" 
                            :multiple="false" 
                            :select-on-tab="true">
                  </v-select>
                </b-form-group>
              </b-col>   

              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <FindObject render="search"
                            type="erpPointsSales" 
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.erp_points_sales_id"
                            :key="crud.form.erp_points_sales_id"
                            :showAllRegister="true" />                 
              </b-col>  
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsPayment" 
                            @select-object="loadMethodsPayment($event)" 
                            :valueID="crud.form.methods_payment_id"
                            :key="crud.form.methods_payment_id" />                 
              </b-col>      
              <b-col md="4" v-if="showShipping">
                <FindObject render="search"
                            type="suppliers" 
                            @select-object="loadShipping($event)" 
                            :where="conditionSuppliersShippingActive"
                            :valueID="crud.form.shipping_id"
                            tag="Transporte"                            
                            :key="crud.form.shipping_id"/>                
              </b-col>     

              <b-col md="4" v-if="parameters.haveReason">
                <FindObject render="search"
                            type="orders-reason" 
                            @select-object="loadReason($event)"                             
                            :valueID="crud.form.reason_id"
                            tag="Motivos"                            
                            :key="crud.form.reason_id"/>                
              </b-col>                                       
            </b-row>
          </tab-content>

          <tab-content title="Productos" icon="fa fa-qrcode" :before-change="orderValidDetailItems">          
            <b-row v-if="crud.form.customers_id.code">
              <b-col lg="12" md="12" sm="12">
                  <Detail :customer_id="crud.form.customers_id.code" 
                          :price_list_id="crud.form.price_list_id"
                          @load-detail="crud.form.detail = $event" 
                          :editPrice="editPrice"
                          :validStock="validStock"
                          :showDiscount="false"
                          :key="'detailOrder_'+itemDetailForceUpdate"
                          :forceUpdate="itemDetailForceUpdate"                          
                          :validItemDuplicated="!allowProductsDuplicates" />                          
                    
                    <b-row class="mt-3 p-3" align-v="end">
                      <b-col md="2">
                        <b-row>
                          <b-col md="5" class="crud-orders-totales-items-header">
                            ITEMS
                          </b-col>
                          <b-col md="7" class="crud-orders-counts-items">
                            <b>{{this.getCantItemOrder}}</b>
                          </b-col>
                        </b-row>
                      </b-col>                    
                      <b-col md="6"></b-col>
                      <b-col md="4" v-if="getCurrencyOrder">
                        <b-row>
                          <b-col md="6" class="crud-orders-totales-items-header">
                            SUBTOTAL
                          </b-col>
                          <b-col md="6" class="crud-orders-totales-items">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyOrder}).format(getSubTotalOrder)}}                          
                          </b-col>                    
                        </b-row>
                        
                        <b-row>
                          <b-col md="6" class="crud-orders-totales-items-header crud-orders-totales-discounts-items text-uppercase">
                            {{ this.getTagDiscount }} (%)
                          </b-col>                     
                          <b-col md="6" class="crud-orders-totales-items">
                            <b-row>
                              <b-col class="p-0"> 
                                <b-form-select 
                                  v-if="getValueDiscount && getValueDiscount.length"
                                  v-model="crud.form.percentage_discount" 
                                  :options="getValueDiscount"
                                  size="sm"
                                  @input="validDiscounts()">
                                </b-form-select>                                                                    
                                <b-form-input 
                                  v-if="!getValueDiscount || !getValueDiscount.length"
                                  v-model="crud.form.percentage_discount" 
                                  type="number" 
                                  min="0" 
                                  max="100"
                                  step="1" 
                                  class="form-control"
                                  size="sm"
                                  @change="validDiscounts()"/>
                              </b-col>
                              <b-col class="crud-orders-totales-discounts-items">
                                {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyOrder}).format(crud.form.discounts_price)}}
                              </b-col>
                            </b-row>
                          </b-col>                    
                        </b-row>                            
                        <b-row>
                          <b-col md="6" class="crud-orders-totales-items-header">
                            TOTAL
                          </b-col>
                          <b-col md="6" class="crud-orders-totales-items-amount">                          
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyOrder}).format(getTotalOrder)}}</b>
                          </b-col>                    
                        </b-row>                                                                  
                      </b-col>
                    </b-row>
              </b-col>        
            </b-row>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered">                      
            <b-row>
              <b-col md="6">                                
                <div v-if="crud.form.customers">            
                  <div>            
                    <b-avatar :src="crud.form.customers.image"
                              v-if="crud.form.customers.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>                                                     
                    &nbsp;<b>{{crud.form.customers.name}}</b>   
                    
                    <span v-if="crud.form.customers.bcra_status">                                                    
                      <span v-if="crud.form.customers.bcra_status.situacion > situationCreditMax">
                        &nbsp;
                        <b-badge variant="danger" :id="'popover-situation-' + crud.form.id">
                          Sit {{crud.form.customers.bcra_status.situacion}}
                        </b-badge>
                        <b-popover :target="'popover-situation-' + crud.form.id" variant="danger" triggers="hover">
                          <template #title>Riesgo Creditício</template>
                          <div v-html="situationCreditMessage(crud.form)"></div>
                        </b-popover>                            
                      </span>
                    </span>                                                

                    <b-icon icon="circle-fill" v-if="!crud.form.customers.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                  </div>

                  <div v-if="crud.form.customers" class="ml-3">
                    <div v-if="!crud.form.customers.email">
                      <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                      <b-badge variant="warning" class="badget-custom">No tiene un email asociado</b-badge>                                                    
                    </div>
                    <div v-else>
                      <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                      <b-link :href="'mailto:' + crud.form.customers.email">
                        {{ crud.form.customers.email }}
                      </b-link> 
                    </div>
                  </div>
                  
                  <div v-if="crud.form.customers.phone" class="ml-3">
                    <b-icon icon="phone"></b-icon> &nbsp;&nbsp;
                    <b-link :href="'tel:' + crud.form.customers.phone">
                      {{ crud.form.customers.phone }}
                    </b-link>               
                  </div>      

                  <b-row v-if="crud.form.customers.observations || crud.form.customers.observations_delivery || crud.form.customers.observations_delivery_address">
                    <b-col>
                      <hr>
                    </b-col>
                  </b-row>
          
                  <b-row v-if="crud.form.customers.observations"  class="mt-2">
                    <b-col class="crud-orders-observations">
                      <b>Observaciones</b>
                      <br>
                      {{ crud.form.customers.observations }}
                    </b-col>
                  </b-row>
                  <b-row v-if="crud.form.customers.observations_delivery"  class="mt-2">              
                    <b-col class="crud-orders-observations">
                      <b>Transporte</b>
                      <br>
                      {{ crud.form.customers.observations_delivery }}
                    </b-col>
                  </b-row>
                  <b-row v-if="crud.form.customers.observations_delivery_address" class="mt-2">
                    <b-col class="crud-orders-observations">
                      <b>Dirección de Entrega</b>
                      <br>
                      {{ crud.form.customers.observations_delivery_address }}
                    </b-col>
                  </b-row> 
                </div>

                <b-row>
                  <b-col md="12">
                    <hr class="mt-2 mb-2">
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Email Alternativo" description="Este email se agrega a la lista de receptores del cliente para este pedido">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.email_alternative"
                                    lazy
                                    required
                                    placeholder="Ingresar un email alternativo">
                      </b-form-input>
                    </b-form-group>
                  </b-col>                         
                </b-row>                
              </b-col>

              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Observaciones">
                      <b-form-textarea                
                        v-model="crud.form.observations"
                        placeholder="Ingresar las observaciones..."
                        rows="5"
                        max-rows="7"
                        lazy
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>         

                  <b-col md="12" class="mt-2">
                    <b-form-group label="Observaciones Internas (No se muestra al cliente)">
                      <b-form-textarea                
                        v-model="crud.form.observations_internal"
                        placeholder="Ingresar las observaciones internas..."
                        rows="5"
                        max-rows="7"
                        lazy
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>         
                </b-row>
              </b-col>
            </b-row>        
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">
            
            <div v-if="itemSelected">
              <b-alert show variant="secondary" v-if="this.itemSelected.is_retail">
                Venta Minorista
              </b-alert>
              <b-alert show variant="primary" v-if="!this.itemSelected.is_retail">
                Venta Mayorista
              </b-alert>
            </div>

            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">

              <b-tab title="General" v-if="itemSelected">
                <table class="table table-hover table-sm">
                  <tbody>
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Número</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.nro_order_internal">
                      <td class="crud-orders-title-table-custom align-middle">Numeración Interna</td>
                      <td class="crud-orders-value-table-custom align-middle" style="color: var(--mdc-theme-primary)">
                        <b>
                          <b-icon icon="link45deg"></b-icon>
                          {{this.itemSelected.nro_order_internal}}
                        </b>
                      </td>
                    </tr>                     
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Fecha</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr v-if="itemSelected.points_sales">
                      <td class="crud-orders-title-table-custom align-middle">Punto de Venta</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.points_sales.point_sale}} - {{this.itemSelected.points_sales.name}}                          
                      </td>
                    </tr>                      
                    <tr v-if="itemSelected.customer">
                      <td class="crud-orders-title-table-custom align-middle">Cliente</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <u>{{this.itemSelected.customer.name}}</u>
                        <div v-if="itemSelected.customer.phone">
                          <b-icon icon="telephone-fill"></b-icon>
                          {{this.itemSelected.customer.phone}}     
                        </div>
                        <div v-if="itemSelected.customer.email">
                          <b-icon icon="inbox"></b-icon>
                          {{this.itemSelected.customer.email}}     
                        </div>                                           
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.user">
                      <td class="crud-orders-title-table-custom align-middle">Usuario</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.user.name}}                        
                      </td>
                    </tr>                         
                    <tr v-if="itemSelected.reason">
                      <td class="crud-orders-title-table-custom align-middle">Motivo</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.reason.name}}                        
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.method_payment">
                      <td class="crud-orders-title-table-custom align-middle">Método de Pago</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.method_payment.name}}                        
                      </td>
                    </tr>           
                    <tr v-if="itemSelected.shipping">
                      <td class="crud-orders-title-table-custom align-middle">Transporte</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.shipping.name}}                        
                      </td>
                    </tr>                                    
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Estado</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <span v-html="getStatusName(itemSelected)"></span>
                        <span v-if="itemSelected.status_system_manufacture" style="font-size: 10px;" class="ml-1">
                          <span v-if="JSON.parse(itemSelected.status_system_manufacture).status == 'ok'" style="color:#c72eda;">
                            <i>({{JSON.parse(itemSelected.status_system_manufacture).message}})</i>
                          </span>
                          <span v-if="JSON.parse(itemSelected.status_system_manufacture).status == 'error'" style="color:#ff1f1f;">
                            <i>{{JSON.parse(itemSelected.status_system_manufacture).message}}</i>
                          </span>
                        </span>                        
                      </td>
                    </tr>                                     
                    <tr v-if="itemSelected.status!='in_process'">
                      <td class="crud-orders-title-table-custom align-middle">SubTotal</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.subtotalItemSelected)}}
                      </td>
                    </tr>  
                    <tr v-if="parseFloat(itemSelected.discounts_price)">
                      <td class="crud-orders-title-table-custom align-middle">{{ this.getTagDiscount }}</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div>
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.discounts_price)}}
                          <span v-if="parseFloat(itemSelected.percentage_discount)>0">
                            ({{parseFloat(this.itemSelected.percentage_discount)}} %)
                          </span>                          
                        </div>
                      </td>
                    </tr>
                    <tr v-if="itemSelected.delivery_info">
                      <td class="crud-orders-title-table-custom align-middle">Entrega</td>
                      <td class="crud-orders-value-table-custom align-middle">                                                
                        <div>
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.delivery_price)}}
                        </div>
                        {{this.itemSelected.delivery_info}} 
                      </td>
                    </tr>
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Total</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div>
                          <div v-if="itemSelected.paid" class="order-total-table-paid">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.total)}}</b>
                          </div>
                          <div v-else class="order-total-table-nopaid">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.total)}}</b>
                          </div>
                        </div>
                      </td>
                    </tr>                    
                    <tr v-if="itemSelected.street_address">
                      <td class="crud-orders-title-table-custom align-middle">Dirección</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.street_address}} {{this.itemSelected.street_number}} {{this.itemSelected.apartament_floor}}
                        <br>
                        {{this.itemSelected.city}} {{this.itemSelected.province}}
                      </td>
                    </tr>
                    <tr v-if="itemSelected.observation">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Entrega</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.observation}}
                      </td>
                    </tr>     
                    <tr v-if="itemSelected.observations_internal">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Internas</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.observations_internal}}
                      </td>
                    </tr>                             
                    <tr v-if="itemSelected.observations_external">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones External</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div v-html="itemSelected.observations_external"></div>
                      </td>
                    </tr>                                       
                    <tr v-if="itemSelected.paid_info">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Pago</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.paid_info}}
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.delivery_code_tracking">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Transporte</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.delivery_code_tracking}}
                      </td>
                    </tr>     
                    <tr v-if="itemSelected.sale && itemSelected.sale.type_voucher && itemSelected.sale.point_sale">
                      <td class="crud-orders-title-table-custom align-middle">Factura</td>                      
                      <td class="crud-orders-value-table-custom align-middle">
                        <b>{{this.itemSelected.sale.type_voucher.name + ' ' + this.itemSelected.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.sale.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                                     
                  </tbody>
                </table>
              </b-tab> 
              <b-tab title="Consumidor Final" v-if="itemSelected && itemSelected.consumer">                
                <table class="table table-hover table-sm">
                  <tbody>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).name && JSON.parse(itemSelected.consumer).lastname">
                      <td class="crud-orders-title-table-custom align-middle">Nombre y Apellido</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{JSON.parse(itemSelected.consumer).name}} {{JSON.parse(itemSelected.consumer).lastname}}
                      </td>
                    </tr> 
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).phone">
                      <td class="crud-orders-title-table-custom align-middle">Teléfono</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <b-icon icon="telephone-fill"></b-icon>
                        {{JSON.parse(itemSelected.consumer).phone}}
                      </td>
                    </tr>             
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).email">
                      <td class="crud-orders-title-table-custom align-middle">Email</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).email}}
                      </td>
                    </tr>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).address">
                      <td class="crud-orders-title-table-custom align-middle">Dirección</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).address}}
                      </td>
                    </tr>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).city">
                      <td class="crud-orders-title-table-custom align-middle">Ciudad</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).city}}
                      </td>
                    </tr>
                    <tr v-if="JSON.parse(itemSelected.consumer) && JSON.parse(itemSelected.consumer).province">
                      <td class="crud-orders-title-table-custom align-middle">Provincia</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{JSON.parse(itemSelected.consumer).province}}
                      </td>
                    </tr>                                                                       
                  </tbody>
                </table>
              </b-tab>                           
              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-orders"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="getProductCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(product)="data">                           
                    <div v-html="getProductName(data.item)"></div>
                  </template>

                  <template v-slot:cell(list)="data">                                                                   
                    <span v-if="data.item.price_list">
                      {{data.item.price_list.reference}}
                    </span>
                    <span v-else>
                      <span v-if="itemSelected.price_list">
                        {{itemSelected.price_list.reference}}
                      </span>
                    </span>
                  </template>

                  <template v-slot:cell(quantity)="data">                              
                    <div  v-if="data.item.observations">

                        <div  :id="'tooltip-button-show-event-' + data.item.id"                              
                              class="text-info">  
                          <b>{{data.item.quantity}}</b>
                          <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                        </div>

                        <b-tooltip  ref="tooltip" 
                                    placement="left"
                                    :target="'tooltip-button-show-event-' + data.item.id">
                          <div v-html="getQuantityVariant(data.item)"></div>
                        </b-tooltip>                                                                           

                    </div>
                    <div v-else>
                      {{data.item.quantity}}
                      <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                    </div>
                  </template> 

                  <template v-slot:cell(unit_price)="data">       
                    <div v-if="data.item.status!='in_process'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.origin_currency_code}).format(data.item.unit_price)}}
                    </div>
                  </template>

                  <template v-slot:cell(total)="data">       
                    <div v-if="data.item.status!='in_process'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.origin_currency_code}).format(data.item.total)}}
                    </div>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Detail from '@/components/inc/cpbtDetail/detailProducts'  
  import Param from '@/config/parameters'
  import moment from 'moment'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Storage from '@/handler/storageSession'
  import Metrics from '@/components/inc/metrics/metrics'  

  export default {
    components: {
      FindObject,
      FormWizard,
      TabContent,
      Detail,
      Metrics,                     
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PEDIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudOrdersSeller',
          elements: {}
        },  
        parameters: {
          haveNroInteral: Helper.hasParametersAccess(Param.P21),
          multipleItems: Helper.hasParametersAccess(Param.P52),
          haveReason: Helper.hasParametersAccess(Param.P54),
        },               
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },
        tableSub : {
          items: [],
          fields: []
        },  
        tableDetail : {
          items: [],
          fields: []
        },           
        crud: {
          form: {
            id: 0,
            date: '',
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,
            price_list_id: 0,
            price_list: null,
            observations_internal: '',      
            erp_points_sales_id: 0,
            erp_points_sales: null,
            methods_payment_id: 0,
            methods_payment: null,
            detail: [],
            email_alternative: '',
            shipping_id: 0,
            shipping: null, 
            discounts_price: 0,
            percentage_discount: 0,
            is_retail: false,         
            reason: null,
            reason_id : 0,                           
          },                                           
        },
        modal: {
          form: {
            active: false,
            title: '',
          },
          detail: {
            active: false, 
            title: '',
          },                                  
        },
        arr: {
          orders: [],   
          export: [],
          filters : {
            customers: [],
            sellers: [],
            status: [],      
            points_sales: [],    
            reasons: [],  
          },  
          select: {
            customers: []
          }                     
        },   
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          status: null,
          source: 'product',
          points_sales: null,
          typeOperations: 'wholesaler',
          number: '',
          reason: null,
        },
        itemSelected: null,   
        configurations: null,  
        itemDetailForceUpdate: 0, 
        itemFilterForceUpdate: 0,
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {   
      this.loadFieldTable()      
      this.filterLoad()    
      this.filterOrders()       
      this.loadStyleConfig()
      this.loadConfigurations()
    },
    watch: {        
      'crud.form.customers_id': function (newQuestion, oldQuestion) {                
        var result = serviceAPI.mostrarCliente(newQuestion.code)        
        result.then((response) => {          
          var object = response.data

          if(object) {
            this.crud.form.customers = object

            if(!this.crud.form.observations && !this.crud.form.id) {
              if(object.observations_delivery_address) {
                this.crud.form.observations = 'ENTREGA: ' + object.observations_delivery_address
              }            
            }               

            if(object.data_fiscal) {
              if(object.data_fiscal.points_sales_id) {
                if(!this.crud.form.erp_points_sales_id) {
                  this.crud.form.erp_points_sales = object.data_fiscal.points_sales
                  this.crud.form.erp_points_sales_id = object.data_fiscal.points_sales_id                  
                }
              }
              if(object.data_fiscal.methods_payment_id) {
                if(!this.crud.form.methods_payment_id) {
                  this.crud.form.methods_payment = object.data_fiscal.methods_payment
                  this.crud.form.methods_payment_id = object.data_fiscal.methods_payment_id                
                }
              }
              if(object.data_fiscal.shipping_id) {
                if(!this.crud.form.shipping_id) {
                  this.crud.form.shipping = object.data_fiscal.shipping
                  this.crud.form.shipping_id = object.data_fiscal.shipping_id
                }
              }               
            } 
            
            if(!this.crud.form.price_list_id) {              
              if(object.price_list) {
                if(object.price_list.list) {              
                  this.crud.form.price_list = object.price_list.list
                  this.crud.form.price_list_id = object.price_list.list.id
                }
              } 
            }                
          }
        })
      }
    },    
    computed: {
      // OTHERS
      subtotalItemSelected() {
        if(this.getAplicationDiscount) {
          if(this.itemSelected) {
            return parseFloat(this.itemSelected.total) - parseFloat(this.itemSelected.delivery_price)
          } else {
            return 0
          }
        } else {
          if(this.itemSelected) {
            return parseFloat(this.itemSelected.total) - parseFloat(this.itemSelected.delivery_price) + parseFloat(this.itemSelected.discounts_price)
          } else {
            return 0
          }          
        }
      },
      situationCreditMax() {
        if(this.configurations) {
          return this.configurations[1].value_number;
        } else {
          return 0;
        }        
      },

      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },      
      conditionSuppliersShippingActive(){
        var condition = []
        condition.push({field: 'active', condition: true})
        condition.push({field: 'shipping', condition: true})
        return condition;
      },   

      // DISCOUNTS
      getTagDiscount() {
        if(this.configurations && this.configurations[19].value_string) {
          return this.configurations[19].value_string
        } else {
          return 'Descuento'
        }        
      },
      getValueDiscount(){
        if(this.configurations && this.configurations[20].value_string) {
          var value = JSON.parse(this.configurations[20].value_string)
          var arr = []
          value.forEach(element => {
            arr.push({
              value:element,
              text: element + ' %'
            })
          });
          return arr
        } else {
          return null
        }        
      },
      getAplicationDiscount() {        
        if(this.configurations) {                    
          if(this.configurations[21].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },      

      // CONFIG DETAIL
      validStock() {
        if(this.configurations) {          
          if(this.configurations[2].value_boolean == 1) {
            return false
          } else {
            return true
          }
        } else {
          return true;
        }    
      },
      editPrice() {
        if(this.configurations) {
          if(this.configurations[3].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },    
      allowProductsDuplicates() {
        if(this.configurations) {
          if(this.configurations[16].value_boolean == 1) {
            return true
          } else {
            if(this.parameters.multipleItems) {
              return true
            } else {
              return false
            }
          }
        } else {
          if(this.parameters.multipleItems) {
            return true
          } else {
            return false
          }
        }         
      }, 

      // PRICES / CANT / CURRENCY
      getSubTotalOrder() {
        var price = 0
        if(this.crud.form.detail) {
          this.crud.form.detail.forEach(element => {                           
            price = price + parseFloat(element.quantity) * parseFloat(element.amount)
          })
        }
        return parseFloat(price)
      },
      getTotalOrder() {
        if(this.getAplicationDiscount) {
          return this.getSubTotalOrder
        } else {
          this.validDiscounts()
          var discounts_price = parseFloat(this.crud.form.discounts_price)

          return this.getSubTotalOrder - discounts_price              
        }              
      },      
      getCantItemOrder() {
        var cant = this.crud.form.detail.length        
        return parseFloat(cant)
      },
      getCurrencyOrder() {        
        var currency = 'ARS'
        if(this.crud.form.detail.length) {          
          currency = this.crud.form.detail[0].product.list.currency.code
        }         
        return currency
      },

      // VALIDA TRANSPORTE       
      showShipping() {
        if(this.configurations) {                    
          if(this.configurations[18].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },         
      validShipping() {
        if(this.configurations) {                    
          if(this.configurations[17].value_boolean == 1) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }    
      },            
    },
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_orders = document.getElementById('view_list_orders')
        var view_footer_orders = document.getElementById('view_footer_orders')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_orders.classList.remove('pr-0')
          view_footer_orders.classList.remove('pr-0')
        } else {          
          view_list_orders.classList.add('pr-0')
          view_footer_orders.classList.add('pr-0')
        }        
      },
      loadFieldTable () {
        // TABLE ORDERS
        this.table.fields.push({key: 'id', label: 'Nº', sortable: true, class:"align-middle text-center", width:"50px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"70px"})                      
        this.table.fields.push({key: 'customers_id', label: 'Cliente', class:"align-middle", width:"210px"})                      
        this.table.fields.push({key: 'total', label: 'Total', class:"align-middle text-right", width:"90px"})                      
        this.table.fields.push({key: 'status', label: 'Estado', class:"align-middle text-center", width:"120px"})          
        if(this.parameters.haveReason) {
          this.table.fields.push({key: 'reason', label: 'Motivo', class:"align-middle text-center", width:"40px"})         
        }        
        this.table.fields.push({key: 'status_system_manufacture', label: '', class:"align-middle text-center", width:"10px"})       
        this.table.fields.push({key: 'paid_info', label: '', class:"align-middle text-center", width:"25px"})                        
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"25px"})                        
        this.table.fields.push({key: 'delivery_code_tracking', label: '', class:"align-middle text-center", width:"25px"})            
        this.table.fields.push({key: 'invoice', label: '', class:"align-middle text-center", width:"25px"})                        

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"50px"})                
        this.tableSub.fields.push({key: 'product', label: 'Producto', class:"align-middle", width:"150px"})                

        // TABLE DETALLE COMPLETO        
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"50px"})                
        this.tableDetail.fields.push({key: 'product', label: 'Producto', class:"align-middle", width:"250px"})                
        this.tableDetail.fields.push({key: 'list', label: 'Lista', class:"align-middle text-truncate", width:"50px"})                
        this.tableDetail.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"70px"})                
        this.tableDetail.fields.push({key: 'unit_price', label: 'Precio Unitario', class:"align-middle", width:"80px"})                
        this.tableDetail.fields.push({key: 'total', label: 'Total', class:"align-middle", width:"80px"})                        
      },
      loadConfigurations() {
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data                  
          this.configurations = data       
          
          this.setStatusAltFilter()
        })      
      },
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.orders.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTableOrdersShow() {
        var arrTable = document.getElementsByClassName('table-orders-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          if(this.parameters.haveNroInteral) {
            arrTable[i].style.minHeight = '390px'
          } else {
            arrTable[i].style.minHeight = '320px'
          }          
        }        
      },
      setConfigTableOrdersHide() {
        var arrTable = document.getElementsByClassName('table-orders-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },      
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-orders-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-orders-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },     

      // SELECT
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          if(object.data_fiscal) {
            if(object.data_fiscal.erp_points_sales_id) {
              if(!this.crud.form.erp_points_sales_id) {
                this.crud.form.erp_points_sales = object.data_fiscal.points_sales
                this.crud.form.erp_points_sales_id = object.data_fiscal.erp_points_sales_id
              }
            }
          }          
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0
        }
      }, 
      loadPointsSales (object) {                
        if(object){
          this.crud.form.erp_points_sales = object
          this.crud.form.erp_points_sales_id = object.id             
        } else {
          this.crud.form.erp_points_sales = null
          this.crud.form.erp_points_sales_id = 0
        }
      },       
      loadMethodsPayment (object) {
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id             
        } else {
          this.crud.form.methods_payment = null
          this.crud.form.methods_payment_id = 0
        }
      },      
      loadShipping (object) {
        if(object){
          this.crud.form.shipping = object
          this.crud.form.shipping_id = object.id             
        } else {
          this.crud.form.shipping = null
          this.crud.form.shipping_id = 0
        }
      }, 
      loadReason (object) {
        if(object){
          this.crud.form.reason = object
          this.crud.form.reason_id = object.id             
        } else {
          this.crud.form.reason = null
          this.crud.form.reason_id = 0
        }
      },                             
      prepareExport(data) {        
        this.arr.export = []
        data.forEach(element => {
          var customersID = 0
          var customersCode = ""
          var customersName = ""
          var pointSalesCode = ""
          var pointSalesName = ""          
          var methodsPaymentID = 0
          var methodsPaymentName = ""          
          var shippingID = 0
          var shippingName = ""
          var reasonID = 0
          var reasonName = ""

          if(element.customer) {
            customersID = element.customer.id
            customersCode = element.customer.code
            customersName = element.customer.name            
          }

          if(element.points_sales) {            
            pointSalesCode = element.points_sales.point_sale
            pointSalesName = element.points_sales.name            
          }

          if(element.method_payment) {            
            methodsPaymentID = element.method_payment.id
            methodsPaymentName = element.method_payment.name            
          }          

          if(element.shipping) {            
            shippingID = element.shipping.id
            shippingName = element.shipping.name            
          } 
          
          if(element.reason) {            
            reasonID = element.reason.id
            reasonName = element.reason.name
          } 

          this.arr.export.push({
            id: element.id,
            pointSalesCode: pointSalesCode,
            pointSalesName: pointSalesName,            
            date: element.date,            
            customersID: customersID,
            customersCode: customersCode,
            customersName: customersName,
            methodsPaymentID: methodsPaymentID,
            methodsPaymentName: methodsPaymentName, 
            shippingID: shippingID,
            shippingName: shippingName,   
            reasonID: reasonID,
            reasonName: reasonName,                 
            discount: element.discounts_price,
            percentage_discount: element.percentage_discount,                              
            total: element.total,            
            status: this.getStatusAltByName(element),
            observations: element.observation,
            observations_external: element.observations_external,
            productID: 0,
            productCode: '',
            productName: '',
            priceListID: 0,
            priceListName: "",                          
            quantity: 0,
            unit_price: 0,
            amount_total: 0,
          })

          element.details.forEach(element1 => {
            var productID = 0
            var productCode = ""
            var productName = ""
            var priceListID = 0
            var priceListName = ""   

            if(element1.products_id && element1.product) {
              productID = element1.product.id
              productCode = element1.product.code
              productName = element1.product.name            
            }

            if(element1.products_colors_id && element1.products_color) {
              productID = element1.products_color.id
              productCode = element1.products_color.code
              productName = element1.products_color.name + " (" + element1.products_color.color.name + ")"
            }            

            if(element1.products_waist_id && element1.products_waist) {
              productID = element1.products_waist.id
              productCode = element1.products_waist.code
              productName = element1.products_waist.name + " (" + element1.products_waist.waist.name + ")"
            }            

            if(element1.products_color_waist_id && element1.products_color_waist) {
              productID = element1.products_color_waist.id
              productCode = element1.products_color_waist.code
              productName = element1.products_color_waist.name + " (" + element1.products_color_waist.color.name + " - " + element1.products_color_waist.waist.name + ")"
            }       

            if(element1.price_list_id && element1.price_list) {              
              priceListID = element1.price_list.id
              priceListName = element1.price_list.name            
            } else {
              if(element.price_list_id && element.price_list) {
                priceListID = element.price_list.id
                priceListName = element.price_list.name                          
              }
            }

            this.arr.export.push({
              productID: productID,
              productCode: productCode,
              productName: productName,
              priceListID: priceListID,
              priceListName: priceListName,                       
              quantity: element1.quantity,
              unit_price: element1.unit_price,
              amount_total: element1.total,
            })
          })
        });
      },

      // ABM MAIN
      add() {
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.customers_id = 0
        this.crud.form.customers = null
        this.crud.form.price_list_id = 0
        this.crud.form.price_list = null
        this.crud.form.observations_internal = ''
        this.crud.form.erp_points_sales_id = 0
        this.crud.form.erp_points_sales = null      
        this.crud.form.methods_payment_id = 0
        this.crud.form.methods_payment = null          
        this.crud.form.detail = []
        this.crud.form.email_alternative = ''
        this.crud.form.shipping_id = 0
        this.crud.form.shipping = null        
        this.crud.form.discounts_price = 0
        this.crud.form.percentage_discount = 0
        this.crud.form.is_retail = false
        this.crud.form.reason_id = 0
        this.crud.form.reason = null
        
        this.detailForceUpdate()

        this.modal.form.title = "Nuevo Pedido"
        this.modal.form.active = true
      },
      save() {
        if(!this.crud.form.erp_points_sales_id) {
          this.$awn.alert('Falta cargar el punto de venta')
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del pedido?', {
          title: 'Generar Pedido',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();

            this.crud.form.customers_id = this.crud.form.customers_id.code
            
            var result = serviceAPI.agregarPedido(this.crud.form);

            /*
            if (this.crud.form.id) {
              var result = serviceAPI.editarPedido(this.crud.form);
            } 
            */

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterOrders()
              this.$awn.success("Pedido generado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },    
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el pedido?', {
          title: 'Cancelar el Pedido',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      orderValidDetailGeneral() {
        var valid = this.crud.form.customers_id && this.crud.form.erp_points_sales_id && this.crud.form.methods_payment_id
        
        if(this.validShipping) {
          valid = valid && this.crud.form.shipping_id
        } 

        if(this.parameters.haveReason) {
          valid = valid && this.crud.form.reason_id
        }

        if(valid) {
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }
          if(!this.crud.form.erp_points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }          
          if(!this.crud.form.methods_payment_id) {
            this.$awn.alert("No se cargó el método de pago");
            return false;
          }         
          if(this.validShipping) {
            if(!this.crud.form.shipping_id) {
              this.$awn.alert("No se cargó el transporte");
              return false;
            }            
          }                
          if(this.parameters.haveReason) {
            if(!this.crud.form.reason_id) {
              this.$awn.alert("No se cargó el motivo");
              return false;
            }            
          }             
        }        
      },      
      orderValidDetailItems() {
          if(this.crud.form.detail.length) {
            return true;
          } else {
            this.$awn.alert("No se cargó ningún item");
            return false;
          }
      },
      detailForceUpdate() {
        this.itemDetailForceUpdate = this.itemDetailForceUpdate + 1
      },
      filterForceUpdate() {
        this.itemFilterForceUpdate = this.itemFilterForceUpdate + 1
      },

      // DISCOUNTS
      validDiscounts() {
        if(this.crud.form.percentage_discount=='') {          
          this.crud.form.percentage_discount = 0
        } else {          
          if( parseFloat(this.crud.form.percentage_discount) < 0 || 
              parseFloat(this.crud.form.percentage_discount) > 100 ) {            
            this.crud.form.percentage_discount = 0
          } 
        }         
        
        var percentage = parseFloat(this.crud.form.percentage_discount)
        this.crud.form.discounts_price = (this.getSubTotalOrder * percentage) / 100        
      },         

      // FILTER
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filterLoadPointSale() 
        this.filterLoadReasons()
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage()
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientesByZonas({ sellers_id: Helper.getSeller().id })        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          this.arr.select.customers = []
          data.forEach(element => {       
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }              

            if(element.active) {
              if(element.code) {
                this.arr.select.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
              } else {
                this.arr.select.customers.push({ code: element.id, label: element.name })            
              }                              
            }                   
          });          

          if(this.customersID) {
            this.validCustomers()
          }
        })   
      },                
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      }, 
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },   
      filterLoadReasons() {
        var result = serviceAPI.obtenerMotivos()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.reasons = []
          data.forEach(element => {                            
            this.arr.filters.reasons.push({ code: element.id, label: element.name })            
          });          
        }) 
      },               
      filterOrders(forceOpenSub=false) {
        this.saveFilterStorage()

        this.filters.sellers = []
        this.filters.sellers.push({
          code: Helper.getSeller().id,
          label: Helper.getSeller().name,
        })   

        this.table.isBusy = true
        var result = serviceAPI.filtrarPedido(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data
          this.table.items = data.data
          this.arr.orders = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {
            if(element.status == 'cancel') {
              element._rowVariant = 'danger'                          
            }
            
            if(forceOpenSub) {              
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }                
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => {             
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },     
      filterOrdersMetrics() {
        this.filterForceUpdate()
      },

      // ORDER DETAIL
      openSub(item) {      
        this.itemSelected = item        
        
        this.tableSub.items = item.details           
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      },          
      openDetail() {              
        this.tableDetail.items = this.itemSelected.details

        this.modal.detail.title = "Detalle del Pedido"
        this.modal.detail.active = true
      },      
      getProductCode(item) {
        var code = ''
        var prod = null

        if(item.code) {
          code = item.code
        }          

        if(item.products_id) {
          if(code) {
            return code
          } else {                      
            return item.product.code
          }
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(code) {
            return code
          } else {
            return prod.code
          }           
        }

        if(item.products_waist_id) {
          prod = item.products_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }
      },      
      getProductName(item) {        
        var name = ''
        var prod = null

        if(item.description) {
          name = item.description
        }  
                
        if(item.products_id) {
          if(name) {
            return name
          } else {
            return item.product.name
          }          
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(name) {
            return name + ' (' + prod.color.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ')'
          }                    
        }

        if(item.products_waist_id) {
          prod = item.products_waist           
          if(name) {
            return name + ' (' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.waist.name + ')'
          }
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(name) {
            return name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          }          
        }
      },
      getQuantityVariant(item) {        
        var html = ''
        if(item.observations) {          
          var variants = JSON.parse(item.observations)
          variants.forEach((element, index) => {
            var name = element[0]
            var cant = element[1]            
            if(parseFloat(cant)>0) {
              html = html + '<i class="fa fa-circle" style="font-size:8px;"></i> ' + name + '- (<b>' + cant + '</b>)<br>'             
            }            
          });          
        }
        return html
      },

      // STATUS ALTERNTIVOS
      getStatusName(item) {
        var name = ''
        var background = 'black'
        var arrStatus = item.status_alt
        
        switch (item.status) {
          case 'in_process':
            name = arrStatus[0]
            background = '#c1bb00'
            break;
        
          case 'closed':
            name = arrStatus[1]
            background = '#1a85e0'
            break;

          case 'in_preparation':
            name = arrStatus[2]
            background = '#582eda'
            break;

          case 'pending_shipping':
            name = arrStatus[3]
            background = '#ce7910'
            break;

          case 'in_transport':
            name = arrStatus[4]
            background = '#33bf8c'
            break;

          case 'delivered':
            name = arrStatus[5]
            background = '#3e9619'
            break;

          case 'cancel':
            name = arrStatus[6]
            background = '#d23829'
            break;
        }

        // system manufacture status
        if(item.status_system_manufacture && this.configurations) {
          if(JSON.parse(item.status_system_manufacture).status == 'ok') {
            name = this.configurations[11].value_string
            background = '#c72eda'
          }
          if(JSON.parse(item.status_system_manufacture).status == 'error') {
            name = this.configurations[12].value_string
            background = '#ff1f1f'
          } 
        }   
        // fin    

        return '<span style="background-color:' + background + '; color: #fff; font-size:12px; font-weight:700; padding: 2px;">'+ name +"</span>"
      },
      setStatusAltFilter() {
        var arrStatus = this.configurations[9].value_string.split(',')
        var haveSystemManufacture = this.configurations[4].value_string
        var showStatusInProcess = this.configurations[13].value_boolean

        this.arr.filters.status = []
        
        if(showStatusInProcess) {
          if(arrStatus[0])
            this.arr.filters.status.push({code:'in_process', label: arrStatus[0]})
        }

        if(arrStatus[1])
          this.arr.filters.status.push({code:'closed', label: arrStatus[1]})
        
        if(haveSystemManufacture) {        
          this.arr.filters.status.push({code:'pending_manufacture', label: this.configurations[11].value_string})          
          this.arr.filters.status.push({code:'error_manufacture', label: this.configurations[12].value_string})          
        }

        if(arrStatus[2])
          this.arr.filters.status.push({code:'in_preparation', label: arrStatus[2]})

        if(arrStatus[3])
          this.arr.filters.status.push({code:'pending_shipping', label: arrStatus[3]})
        
        if(arrStatus[4])
          this.arr.filters.status.push({code:'in_transport', label: arrStatus[4]})
        
        if(arrStatus[5])
          this.arr.filters.status.push({code:'delivered', label: arrStatus[5]})

        if(arrStatus[6])
          this.arr.filters.status.push({code:'cancel', label: arrStatus[6]})              
      },    
      getStatusAltByName(item) {
        var name = ''        
        var arrStatus = item.status_alt
        
        switch (item.status) {
          case 'in_process':
            name = arrStatus[0]            
            break;
        
          case 'closed':
            name = arrStatus[1]            
            break;

          case 'in_preparation':
            name = arrStatus[2]            
            break;

          case 'pending_shipping':
            name = arrStatus[3]            
            break;

          case 'in_transport':
            name = arrStatus[4]            
            break;

          case 'delivered':
            name = arrStatus[5]            
            break;

          case 'cancel':
            name = arrStatus[6]            
            break;
        }

        // system manufacture status
        if(item.status_system_manufacture && this.configurations) {
          if(JSON.parse(item.status_system_manufacture).status == 'ok') {
            name = this.configurations[11].value_string      
          }
          if(JSON.parse(item.status_system_manufacture).status == 'error') {
            name = this.configurations[12].value_string
          } 
        }   
        // fin   

        return name
      },      
      

      // CREDIT SITUATION 
      situationCreditMessage(item) {
        if(item.customer) {
          var message = "<b>Fecha:</b> "+ moment(item.customer.bcra_status.fecha).format('DD/MM/YYYY') +"<br><b>Situación:</b> "+ item.customer.bcra_status.situacion +"<br><b>Cheques Rechazados:</b> " + item.customer.bcra_status.rechazos
          return message;
        } else {
          return '';
        }        
      },    

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_crud_orders_sellers', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_crud_orders_sellers')) {
          this.filters = JSON.parse(Storage.getValue('filter_crud_orders_sellers'))
        }         
      },         
    }
  }
</script>
<style>
  .crud-orders-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-orders-code {
    color: gray;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-orders-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-orders-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-orders-value-table-custom {
    padding-left: 5px;
    white-space: pre-line;
  }  
  .table-full-detail-orders {
    overflow: auto;
    max-height: 350px;    
  }
  .orders-alert-retailer {
    font-weight: bold;
    color: gray;
    font-size: 12px;
  }
  .orders-alert-noretailer {
    font-weight: bold;
    color: darkblue;
    font-size: 12px;
  } 
  .order-total-table-paid {
    color: darkgreen;
    font-weight: 700;
  } 
  .order-total-table-nopaid {
    color: darkred;
    font-weight: 700;
  }
  .crud-orders-nro-internal {
    color: var(--mdc-theme-primary);
    font-size: 12px;
  }
  .crud-new-orders-button-add {
    margin-top: 29px;
  }
  .crud-orders-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-orders-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }    
  .crud-orders-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }
  .crud-orders-totales-items-amount {
    font-size: 15px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;    
    border-width: 1px;    
  }      
  #view_list_orders .card-header {
    padding: 5px;
  }    
  .crud-orders-totales-discounts-items {
    padding-top: 5px;
  }  
  .crud-orders-wizard .wizard-header {
    padding: 0px;
  }  
</style>
<style scoped>
  hr {
    margin-top: 5px;
    margin-bottom: 5px;    
  }
  .crud-orders-observations {
    overflow: auto;
    max-height: 70px; 
    font-size: 12px;   
  }
  .b-avatar {
    width: 25px;
    height: 25px;    
  }
</style>